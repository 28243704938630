@font-face {
  font-family: Mulish;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mulish-cyrillic-ext-400-normal.877c50a9.woff2") format("woff2"), url("mulish-all-400-normal.4858a815.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mulish-cyrillic-400-normal.f0ececbc.woff2") format("woff2"), url("mulish-all-400-normal.4858a815.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mulish-vietnamese-400-normal.f9bee029.woff2") format("woff2"), url("mulish-all-400-normal.4858a815.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mulish-latin-ext-400-normal.d3d8a465.woff2") format("woff2"), url("mulish-all-400-normal.4858a815.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mulish-latin-400-normal.ac93a5dd.woff2") format("woff2"), url("mulish-all-400-normal.4858a815.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("mulish-cyrillic-ext-500-normal.543ed5c5.woff2") format("woff2"), url("mulish-all-500-normal.1187166e.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("mulish-cyrillic-500-normal.4cdaf7bf.woff2") format("woff2"), url("mulish-all-500-normal.1187166e.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("mulish-vietnamese-500-normal.f4e28043.woff2") format("woff2"), url("mulish-all-500-normal.1187166e.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("mulish-latin-ext-500-normal.f76055b3.woff2") format("woff2"), url("mulish-all-500-normal.1187166e.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("mulish-latin-500-normal.b6f68554.woff2") format("woff2"), url("mulish-all-500-normal.1187166e.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("mulish-cyrillic-ext-800-normal.f6b4b1b1.woff2") format("woff2"), url("mulish-all-800-normal.5768d3a9.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("mulish-cyrillic-800-normal.190e78ca.woff2") format("woff2"), url("mulish-all-800-normal.5768d3a9.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("mulish-vietnamese-800-normal.46fd85b7.woff2") format("woff2"), url("mulish-all-800-normal.5768d3a9.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("mulish-latin-ext-800-normal.a645ffaf.woff2") format("woff2"), url("mulish-all-800-normal.5768d3a9.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("mulish-latin-800-normal.fb0fbcb5.woff2") format("woff2"), url("mulish-all-800-normal.5768d3a9.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --color-razem-start: #7c1d65;
  --color-razem-intermediate: #d51c4d;
  --color-razem-stop: #ef4348;
  --color-primary: #8a0f59;
  --color-primary-hover: #730d4a;
  --color-primary-active: #5c0a3b;
  --color-main: #000;
  --color-muted: #8d8d8d;
  --color-background: #fff;
  --color-background-muted: #fafafa;
  --color-contrast: #fff;
  --box-shadow: 0 0 1px #000000b3;
  --box-shadow-card: 0 0 100px #0000001a;
  --box-shadow-accordion: 0 0 2rem #0000001a;
  --size-radius: 1rem;
  --size-max-content: 50rem;
  --size-max-content-wide: 55rem;
  --font-weight-normal: 400;
  --font-weight-headline: 800;
  --font-weight-prominent: 500;
  --font-size-prominent: 1.4rem;
}

html, body {
  font-family: Mulish, sans-serif;
  font-size: 100%;
  line-height: 1.6em;
  font-weight: var(--font-weight-normal);
  width: 100%;
  margin: 0;
  padding: 0;
}

h1, h2, h3 {
  font-weight: var(--font-weight-headline);
  text-transform: uppercase;
  margin: 1rem 0;
  padding: 0;
}

h1 {
  font-size: 2rem;
  line-height: 2.2rem;
}

h2 {
  font-size: 1.6rem;
  line-height: 1.8rem;
}

p {
  margin: 1rem 0;
}

hr {
  border: none;
  border-bottom: 1px solid #0000001a;
  outline: none;
  height: 0;
  margin: 2rem 0;
}

body {
  background: var(--color-background);
  color: var(--color-main);
  flex-direction: column;
  min-height: 100vh;
  display: flex;
}

body .lead {
  color: var(--color-contrast);
  font-weight: var(--font-weight-prominent);
  font-size: var(--font-size-prominent);
  justify-content: center;
  gap: 1em;
  margin: 0;
  padding: 2rem 1rem 5rem;
  line-height: 1.8rem;
  display: grid;
}

body .lead__content {
  max-width: var(--size-max-content);
}

body .lead h1 {
  text-shadow: var(--box-shadow);
  text-align: center;
  margin: 2em 0 1em;
}

body .lead p {
  text-shadow: var(--box-shadow);
}

body .lead--razem {
  background: var(--color-primary);
}

body .lead--padded {
  padding: 1rem 1rem 20rem;
}

body .lead--longer {
  padding-bottom: 26rem;
}

main {
  margin: 0;
  padding: 0;
}

.paper {
  background: var(--color-contrast);
  border-radius: var(--size-radius);
  max-width: var(--size-max-content-wide);
  box-shadow: var(--box-shadow-card);
  width: 100%;
  padding: 2rem;
}

.paper--center {
  text-align: center;
}

.paper--success {
  margin-bottom: 6rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.paper--success h1 {
  margin-bottom: 2rem;
}

.layout--no-background {
  background: none;
  border-radius: 0;
  padding: 0 1rem;
}

.layout--middle {
  justify-content: center;
  padding: 0;
  display: flex;
}

.layout--grid {
  justify-content: center;
  gap: 2rem;
  margin: 0;
  display: grid;
}

.layout--pull-up {
  margin: -19rem 1rem 1rem;
}

footer {
  justify-content: center;
  padding: 8rem 0;
  display: flex;
}

footer .footer__content {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: grid;
}

@media screen and (width >= 500px) {
  footer .footer__content {
    max-width: var(--size-max-content);
    grid-template-columns: 1fr 1fr;
  }
}

footer .footer__content .logo {
  text-align: center;
}

nav {
  box-shadow: var(--box-shadow);
  background: var(--color-background);
  place-content: center;
  width: 100%;
  padding: 2rem 0;
  display: flex;
}

nav h1 {
  text-align: center;
  margin: 0;
}

.button-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.button {
  cursor: pointer;
  background: var(--color-primary);
  color: var(--color-contrast);
  text-align: center;
  text-transform: uppercase;
  box-shadow: var(--box-shadow);
  text-shadow: var(--box-shadow);
  border: none;
  border-radius: 1rem;
  gap: .5em;
  padding: 1rem 3rem;
  font-weight: 800;
  text-decoration: none;
  display: grid;
}

.button:visited {
  color: var(--color-contrast);
}

.button:hover {
  background: var(--color-primary-hover);
}

.button:active {
  background: var(--color-primary-active);
}

.button span:nth-child(2) {
  font-size: 2rem;
}

.form {
  grid-template-areas: "firstName"
                       "lastName"
                       "email"
                       "country"
                       "phone"
                       "city"
                       "zipCode"
                       "message"
                       "privacyPolicy"
                       "policy"
                       "submit"
                       "recaptcha";
  gap: 1rem;
  display: grid;
}

@media screen and (width >= 1000px) {
  .form {
    grid-template-areas: "firstName lastName email"
                         "country phone city"
                         "zipCode privacyPolicy privacyPolicy"
                         "policy policy policy"
                         ". submit ."
                         "recaptcha recaptcha recaptcha";
  }

  .form .privacy-policy {
    margin-right: 15rem;
    padding-top: 2rem;
  }
}

.form__group {
  width: 100%;
}

.form__group--amount {
  grid-area: choose;
}

.form__label {
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: var(--font-weight-headline);
}

.form__label--with-input {
  gap: .5rem;
  display: grid;
}

.form__label--with-input input {
  box-shadow: 0 0 1px 1px var(--color-primary);
  border-radius: var(--size-radius);
  border: none;
  padding: 1rem;
  font-size: 1rem;
}

.form__label--with-input input:not([type="checkbox"]) {
  -webkit-appearance: none;
}

.form__label--with-input input:focus {
  box-shadow: 0 0 0 2px var(--color-primary-active);
  outline: none;
}

.form__label--with-input input[disabled] {
  color: var(--color-muted);
}

.form__label--checkbox {
  text-transform: none;
  flex-direction: row-reverse;
  align-items: flex-start;
  font-size: 1rem;
  display: flex;
}

.form__label--checkbox input {
  margin-top: .3em;
  margin-right: 1rem;
}

.form__label--checkbox input, .form__label--checkbox input:focus {
  box-shadow: none;
}

.form .payment-disclaimer {
  grid-area: disclaimer;
  padding: 0 1rem;
  font-size: smaller;
}

.form .privacy-policy {
  grid-area: privacyPolicy;
}

.form .recaptcha {
  text-align: center;
  grid-area: recaptcha;
}

.form .recaptcha .grecaptcha-badge {
  visibility: hidden;
}

.faq {
  justify-content: center;
  display: flex;
}

.faq__container {
  max-width: var(--size-max-content);
  width: 100%;
}

.faq__header {
  text-align: center;
  margin: 5rem 0 3rem;
}

.faq .accordion {
  gap: 1rem;
  width: 100%;
  display: grid;
}

.faq details {
  background: var(--color-contrast);
  border-radius: var(--size-radius);
  box-shadow: var(--box-shadow-accordion);
  padding: 1rem;
}

.faq details summary {
  cursor: pointer;
  text-align: left;
  font-weight: var(--font-weight-prominent);
  font-size: var(--font-size-prominent);
  border: none;
  margin: 0;
  padding: 1rem 1rem 1rem 2rem;
  list-style: none;
  position: relative;
}

.faq details summary::-webkit-details-marker {
  display: none;
}

.faq details summary:active, .faq details summary:focus {
  outline: none;
}

.faq details summary:before {
  content: "";
  color: var(--color-primary);
  transform-origin: center;
  background: url("chevron-left.56cfe075.svg") center no-repeat;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  transition: all .1s;
  display: flex;
  position: absolute;
  top: 1rem;
  left: 0;
}

.faq details[open] summary:before {
  color: var(--color-primary-hover);
  transform: rotate(90deg);
}

.faq details .accordion__details__body {
  padding: 0 2rem;
}

.qrcode {
  max-width: min(80%, 400px);
}

.text-center {
  text-align: center;
}

.bold {
  font-size: 1.2em;
  font-weight: 800;
  line-height: 1.4em;
}

.logo {
  width: 128px;
  height: 128px;
}
/*# sourceMappingURL=index.00f3dc52.css.map */
