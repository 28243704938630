@import './fonts';
@import './variables';
@import './layout';
@import './components/navigation';
@import './components/button';
@import './components/form';
@import './components/faq';

.qrcode {
  max-width: min(80%, 400px);
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: 800;
  font-size: 1.2em;
  line-height: 1.4em;
}

.logo {
  width: 128px;
  height: 128px;
}
