@import './sass-variables';

:root {
  --color-razem-start: #{$start};
  --color-razem-intermediate: #{$intermediate};
  --color-razem-stop: #{$stop};
  --color-primary: #{$razem};
  --color-primary-hover: #{darken($razem, 5%)};
  --color-primary-active: #{darken($razem, 10%)};
  --color-main: #000;
  --color-muted: #8d8d8d;
  --color-background: #fff;
  --color-background-muted: #FAFAFA;
  --color-contrast: #fff;
  --box-shadow: 0 0 1px rgb(0 0 0 / 70%);
  --box-shadow-card: 0 0 100px rgba(0,0,0,0.1);
  --box-shadow-accordion: 0 0 2rem rgba(0,0,0,0.1);
  --size-radius: 1rem;
  --size-max-content: 50rem;
  --size-max-content-wide: 55rem;

  --font-weight-normal: 400;
  --font-weight-headline: 800;
  --font-weight-prominent: 500;
  --font-size-prominent: 1.4rem;
}
