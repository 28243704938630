.faq {
  display: flex;
  justify-content: center;

  &__container {
    width: 100%;
    max-width: var(--size-max-content);
  }

  &__header {
    text-align: center;
    margin: 5rem 0 3rem 0;
  }

  .accordion {
    width: 100%;
    display: grid;
    gap: 1rem;
  }

  details {
    background: var(--color-contrast);
    border-radius: var(--size-radius);
    box-shadow: var(--box-shadow-accordion);
    padding: 1rem;

    summary {
      position: relative;
      padding: 1rem 1rem 1rem 2rem;
      margin: 0;
      cursor: pointer;
      text-align: left;
      border: none;
      list-style: none;
      font-weight: var(--font-weight-prominent);
      font-size: var(--font-size-prominent);

      &::-webkit-details-marker {
        display: none;
      }

      &:active,
      &:focus {
        outline: none;
      }

      &:before {
        position: absolute;
        top: 1rem;
        left: 0;
        content: '';
        background: url('../img/chevron-left.svg') center center no-repeat;
        width: 1em;
        height: 1em;
        transition: 100ms;
        color: var(--color-primary);
        transform-origin: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &[open] {
      summary:before {
        color: var(--color-primary-hover);
        transform: rotate(90deg);
      }
    }

    .accordion__details__body {
      padding: 0 2rem;
    }
  }
}



