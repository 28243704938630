.form {
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "firstName"
    "lastName"
    "email"
    "country"
    "phone"
    "city"
    "zipCode"
    "message"
    "privacyPolicy"
    "policy"
    "submit"
    "recaptcha";

  @media screen and (min-width: 1000px) {
    & {
      grid-template-areas:
        "firstName lastName email"
        "country phone city"
        "zipCode privacyPolicy privacyPolicy"
        "policy policy policy"
        ". submit ."
        "recaptcha recaptcha recaptcha";
    }

    .privacy-policy {
      padding-top: 2rem;
      margin-right: 15rem;
    }
  }

  &__group {
    width: 100%;

    &--amount {
      grid-area: choose;
    }
  }

  &__label {
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: var(--font-weight-headline);

    &--with-input {
      display: grid;
      gap: .5rem;

      input {
        padding: 1rem;
        font-size: 1rem;
        border: none;
        box-shadow: 0 0 1px 1px var(--color-primary);
        border-radius: var(--size-radius);

        &:not([type="checkbox"]) {
          -webkit-appearance: none;
        }

        &:focus {
          box-shadow: 0 0 0 2px var(--color-primary-active);
          outline: none;
        }
      }

      input[disabled] {
        color: var(--color-muted);
      }
    }

    &--checkbox {
      display: flex;
      flex-direction: row-reverse;
      font-size: 1rem;
      text-transform: none;
      align-items: flex-start;

      input {
        margin-right: 1rem;
        margin-top: .3em;
        &, &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .payment-disclaimer {
    grid-area: disclaimer;
    padding: 0 1rem;
    font-size: smaller;
  }

  .privacy-policy {
    grid-area: privacyPolicy;
  }

  .recaptcha {
    grid-area: recaptcha;
    text-align: center;

    .grecaptcha-badge { visibility: hidden; }
  }
}
