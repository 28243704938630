html, body {
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-family: Mulish, sans-serif;
  line-height: 1.6em;
  font-weight: var(--font-weight-normal);
}

* {
  //box-sizing: border-box;
}

h1, h2, h3 {
  margin: 1rem 0;
  font-weight: var(--font-weight-headline);
  text-transform: uppercase;
  padding: 0;
}

h1 {
  font-size: 2rem;
  line-height: 2.2rem;
}

h2 {
  font-size: 1.6rem;
  line-height: 1.8rem;
}

p {
  margin: 1rem 0;
}

hr {
  border: none;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  outline: none;
  height: 0;
  margin: 2rem 0;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--color-background);
  color: var(--color-main);

  .lead {
    color: var(--color-contrast);
    font-weight: var(--font-weight-prominent);
    display: grid;
    justify-content: center;
    margin: 0;
    padding: 2rem 1rem 5rem;
    gap: 1em;
    font-size: var(--font-size-prominent);
    line-height: 1.8rem;

    &__content {
      max-width: var(--size-max-content);
    }

    h1 {
      text-shadow: var(--box-shadow);
      text-align: center;
      margin: 2em 0 1em;
    }

    p {
      text-shadow: var(--box-shadow);
    }

    &--razem {
      background: var(--color-primary);
    }

    &--padded {
      padding: 1rem 1rem 20rem;
    }

    &--longer {
      padding-bottom: 26rem;
    }
  }
}

main {
  margin: 0;
  padding: 0;
}

.paper {
  background: var(--color-contrast);
  border-radius: var(--size-radius);
  padding: 2rem;
  max-width: var(--size-max-content-wide);
  width: 100%;
  box-shadow: var(--box-shadow-card);

  &--center {
    text-align: center;
  }

  &--success {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 6rem;

    h1 {
      margin-bottom: 2rem;
    }
  }
}

.layout {
  &--no-background {
    padding: 0 1rem;
    background: none;
    border-radius: 0;
  }

  &--middle {
    padding: 0;
    display: flex;
    justify-content: center;
  }

  &--grid {
    display: grid;
    justify-content: center;
    gap: 2rem;
    margin: 0;
  }

  &--pull-up {
    margin: -19rem 1rem 1rem;
  }
}

footer {
  display: flex;
  justify-content: center;
  padding: 8rem 0;

  .footer__content {
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media screen and (min-width: 500px) {
      grid-template-columns: 1fr 1fr;
      max-width: var(--size-max-content);
    }

    .logo {
      text-align: center;
    }
  }
}
